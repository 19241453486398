import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>We can’t wait to see what you build.</h2>
          <img src="/img/testimonials/code_snippet.png" alt="Example" style={{ width: '80%', height: 'auto' }} />
        </div>
      </div>
    </div>
  );
};
